<template>
  <!-- 授信额度详情 -->
  <div class="hangOutDetails page-info-content">
    <!-- 详情展示 -->
    <Details-page :details-title="'申请企业信息'" :details-item-arr="detailsItemArr" :detail-obj="detailObj" />
    <Details-page
      v-if="Number(detailCreditObj.creditTotalAmt)>0"
      class="detailsBox"
      :table-title="detailCreditObj.agentRateLadderConfigList && detailCreditObj.agentRateLadderConfigList.length > 0 ? '采购阶梯费用' : ''"
      :item-data="detailCreditObj.agentRateLadderConfigList && detailCreditObj.agentRateLadderConfigList.length > 0 ? itemData : []"
      :list-data="detailCreditObj.agentRateLadderConfigList || []"
      :details-title="'授信方案'"
      :detail-obj="detailCreditObj"
      :details-item-arr="detailsCreditItemArr"
    >
      <template>
        <el-descriptions v-if="Number(detailCreditObj.creditTotalAmt)>0" :content-style="{'font-size':'14px'}" :label-style="{'font-size':'14px'}" title="计息方式">
          <el-descriptions-item v-if="detailCreditObj.interestAccrual1" label="按比率计息">
            {{ detailCreditObj.interestAccrual1 }}
          </el-descriptions-item>
          <el-descriptions-item v-if="detailCreditObj.interestAccrual2" label="周期加价">
            {{ detailCreditObj.interestAccrual2 }}
          </el-descriptions-item>
          <el-descriptions-item v-if="detailCreditObj.interestAccrual3" label="固定加价">
            {{ detailCreditObj.interestAccrual3 }}
          </el-descriptions-item>
        </el-descriptions>
      </template>
    </Details-page>
  </div>
</template>

<script>
import { agentFinancialInfoDetails } from '@/api/generationMining.js'
import { roundUpToInteger } from '@/utils/util'
import DetailsPage from '@/components/DetailsPage.vue'
export default {
  components: {
    DetailsPage
  },
  data() {
    return {
      breadcrumbItem: [{ path: '/', name: '首页' }, { path: '/quotaManage/index', name: '额度管理' }, { path: '', name: '授信申请详情' }],
      detailObj: {},
      detailCreditObj: {},
      itemData: [
        { label: '金额下限', prop: 'lowerLimit' },
        { label: '金额上限', prop: 'upperLimit' },
        { label: '基数(元/吨)', prop: 'base' },
        { label: '梯度费率计算公式', prop: 'operFormula' }
      ],
      detailsCreditItemArr: [
        { label: '授信额度(万元)', money: 'creditTotalAmt' },
        { label: '保证金比例(%)', value: 'depositRate' },
        { label: '额度到期日', value: 'validDate' },
        { label: '保证金是否抵扣货款', value: 'depostDeductPayment', child: [{ id: '0', val: '否' }, { id: 1, val: '是' }] },
        { label: '交易类型', value: 'tradeType' },
        { download: 'proxyContractUrl', label: '代理采购协议', value: 'proxyContractTemplateName', whetherShown: true },
        { label: '采购期限(天)', value: 'maxPurchaseDays' },
        { label: '货款延期支付天数(天)', value: 'deferredPaymentDays' },
        { label: '逾期费率', value: 'overdueInterest' }

      ],
      detailsItemArr: [
        { label: '公司名称', value: 'cmpName' },
        { label: '统一社会信用代码', value: 'cmpUnicode' },
        { label: '所在地址', value: 'currAddr' },
        { label: '营业执照', imageSrc: 'businessLicenseNamePath' },
        { label: '法人身份证正面', imageSrc: 'leadIdcardFrontNamePath' },
        { label: '法人身份证正面', imageSrc: 'leadIdcardBackNamePath' },
        { label: '企业征信报告', value: 'cmpCreditReportName', link: 'cmpCreditReportNamePath' },
        { label: '财务报表', value: 'financialReportsName', link: 'financialReportsNamePath' },
        { label: '资产负债表', value: 'balanceSheetName', link: 'balanceSheetNamePath' },
        { label: '公司章程', value: 'rulesName', link: 'rulesNamePath' },
        { label: '纳税申报表', value: 'taxReturnName', link: 'taxReturnNamePath' },
        { label: '流水表', value: 'flowMeterName', link: 'flowMeterNamePath' },
        { label: '上下游前五交易报表', value: 'topFiveTransacName', link: 'topFiveTransacNamePath' },
        { label: '其他附件', value: 'othAccessName', link: 'othAccessNamePath' },
        { label: '联系人', value: 'contacts' },
        { label: '联系电话', value: 'contactsPhone' },
        { label: '状态', value: 'creditStatus', child: this.$store.getters.getDictionaryItem('CREDIT_STATUS'), whetherShown: true },
        { label: '驳回原因', value: 'rejectionReasons', whetherShown: true }
      ]
    }
  },
  created() {
    if (this.$route.query.id) {
      this.getDetails(this.$route.query.id)
    }
  },
  methods: {
    cancel() {
      this.$router.back(-1)
    },
    getDetails(id) {
      agentFinancialInfoDetails(id, res => {
        if (res.data.financialInfo) {
          res.data.financialInfo.creditStatus = res.data.creditStatus + ''
          res.data.financialInfo.rejectionReasons = res.data.rejectionReasons
        }
        if (res.data.agentInterestSchs && res.data.agentInterestSchs.length > 0) {
          res.data.agentInterestSchs.forEach(item => {
            switch (item.rateUnit) {
              case 1:
                if (item.rateValue) {
                  res.data.interestAccrual1 = `采购费率${item.rateValue}%/${item.rateCycle === 1 ? '天' : '年'}`
                }
                break
              case 2:
                if (item.rateValue) {
                  res.data.interestAccrual2 = `采购费率${item.rateValue}元/吨/${item.rateCycle === 1 ? '天' : '周'}`
                }
                break
              case 3:
                if (item.rateValue) {
                  res.data.interestAccrual3 = `采购费率${item.rateValue}元/吨`
                }
                break
            }
          })
        }
        this.detailCreditObj = { ...res.data }
        this.detailCreditObj.creditTotalAmt = roundUpToInteger((Number(this.detailCreditObj.creditTotalAmt)) || 0) / 10000
        if (this.detailCreditObj.overdueInterest) this.detailCreditObj.overdueInterest = this.detailCreditObj.overdueInterest + '%/天'
        // 代采采购费用
        if (this.detailCreditObj.agentInterestSch) {
          this.detailCreditObj.procurementCosts = this.detailCreditObj.agentInterestSch.rateValue
          switch (this.detailCreditObj.agentInterestSch.rateUnit) {
            case 1:
              this.detailCreditObj.procurementCosts += '%'
              break
            case 2:
              this.detailCreditObj.procurementCosts += '元/吨'
              break
            case 3:
              this.detailCreditObj.procurementCosts += '元/吨'
              break
          }
          switch (this.detailCreditObj.agentInterestSch.rateCycle) {
            case 1:
              this.detailCreditObj.procurementCosts += '/天'
              break
            case 2:
              this.detailCreditObj.procurementCosts += '/周'
              break
            case 3:
              this.detailCreditObj.procurementCosts += '/月'
              break
            case 4:
              this.detailCreditObj.procurementCosts += '/年'
              break
          }
        }
        // 交易类型
        this.detailCreditObj.tradeType = (this.detailCreditObj.lockPrice === 1 ? '锁价' : ' ') + (this.detailCreditObj.lockQuantity === 1 ? ' 锁量' : ' ')
        if (res.data.financialInfo) this.detailObj = { ...res.data.financialInfo }
        this.detailObj.fundsCmpName = res.data.fundsCmpName
      })
    }
  }
}
</script>

<style lang="scss">
.demo-ruleForm {
  padding: 32px 52px;
}
.hangOutDetails {
  .el-tabs {
    margin: 32px 0 16px;

    .detailsBox {
      transform: translateY(-20px);
    }
  }

  // .el-form-item__content {
  //   width: calc(100% - 140px);

  //   .el-select {
  //     width: 100%;
  //   }
  // }

  .lastOne {
    width: 100%;
  }

  .table-overspread {
    .el-form-item__content {
    width: 84%;
    }
  }

  .table {
    width: 100%;

    td {
      border: 1px solid #ededed;
      text-align: center !important;

      .icons {
        font-size: 26px;
        cursor: pointer;
      }

      .intervalValue {
        width: 48%;
        margin: 0 1%;
      }

      input {
        text-align: center;
      }
    }
  }

  .el-date-editor {
    width: 100%;
  }

  .buyingExpenses {
    display: flex;
    align-items: center;
    >span{
      width: 40px;
      text-align: right;
    }
    .interval {
      margin: 0 6px;
    }
  }

  .input-unit {
    .el-form-item__content .el-input-group {
      display: flex;
    }

    .el-input-group__append {
      flex-shrink: 0;
      width: 60%;
      display: flex;
      padding: 0;

      .el-select {
        margin: 0;
      }
    }
  }

  .maxWidth {
    width: 100%;

    .el-form-item__content {
      width: calc(100% - 190px);
    }
  }
}
</style>
